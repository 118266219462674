// Images import
// ================================================================
import userimage_fallback from 'public/img/user-placeholder.svg';

// Required libs import
// ================================================================
import React, { useCallback, useRef, useState } from 'react';
import PT from 'prop-types';

// Utility functions and constants
// ================================================================
import useBodyClick from 'trendolizer-ui/build/hooks/useBodyClick';

// Trendolizer UI imports
// ================================================================
import Image from 'trendolizer-ui/build/Image';
import Button from 'trendolizer-ui/build/Button';

// Component declaration
// ================================================================
export default function UserInfo(props) {
  const { image, position, fullname, handleLogout } = props;
  const [show, setShow] = useState(false);
  const $el = useRef();

  // Wrap click handler with menu closing functionality
  // ================================================================
  const onMenuClick = useCallback(async () => {
    await handleLogout();
    setShow(false);
  }, [handleLogout]);

  // Cached handler for menu open
  // ================================================================
  const showMenu = useCallback(() => setShow(true), []);

  // Watch for body clicks
  // ================================================================
  useBodyClick($el, () => setShow(false));
  return (
    <div className='UserInfo' ref={$el}>
      <div className='UserInfo-content' onClick={showMenu}>
        <Image
          className='UserInfo-image'
          src={image}
          ratio='1'
          alt={fullname}
          fallback={userimage_fallback}
        />
        <b className='UserInfo-name is-ellipsis'>{fullname}</b>
        <small className='UserInfo-position is-ellipsis'>{position}</small>
      </div>
      {show ? (
        <div className='UserInfo-menu layout-paper' data-paper-depth='3'>
          <Button
            icon='logout'
            onClick={onMenuClick}
            text='Logout'
            className='UserInfo-menu-button'
          />
        </div>
      ) : null}
    </div>
  );
}

// PropTypes declaration
// ================================================================
UserInfo.propTypes = {
  image: PT.string,
  fullname: PT.string,
  handleLogout: PT.func.isRequired,
  position: PT.string
};

UserInfo.defaultProps = {
  fullname: 'Not logged in',
  position: 'Log in to use search'
};
