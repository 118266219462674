// Required libs import
// ================================================================
import React, { useCallback } from 'react';
import PT from 'prop-types';
import { AutoSizer, Grid } from 'react-virtualized';

// Other components import
// ================================================================
import Result from './Result';
import NoResults from './NoResults';

// Component declaration
// ================================================================
export default function ResultList(props) {
  const { loading, data, onScrollEnd, onSelect, selected } = props;

  const rowCount = data.length;

  // Infinite loading logic callback
  // ================================================================
  const onSectionRendered = useCallback(
    ({ rowOverscanStopIndex }) => {
      if (
        !loading &&
        rowOverscanStopIndex &&
        rowCount - rowOverscanStopIndex === 1
      ) {
        onScrollEnd(rowCount);
      }
    },
    [onScrollEnd, loading, rowCount]
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Grid
          autoContainerWidth
          width={width}
          height={height}
          cellRenderer={({ key, rowIndex, style }) => (
            <div key={key} style={style} className='ResultList-item'>
              <Result
                {...data[rowIndex]}
                onSelect={onSelect}
                selected={selected.has(data[rowIndex].url)}
              />
            </div>
          )}
          columnWidth={width}
          rowHeight={256}
          tabIndex={null}
          columnCount={1}
          onSectionRendered={onSectionRendered}
          rowCount={rowCount}
          noContentRenderer={() => (
            <NoResults
              queryInputId='#query'
              sortingId='#sort'
              sortingSelector='.QueryForm-sorting .Select-control'
              providersId='#providers'
              providersSelector='.QueryForm-providers .Select-control'
              submitId='#submit'
              resetId='#reset'
            />
          )}
          overscanRowCount={3}
        />
      )}
    </AutoSizer>
  );
}

// PropTypes declaration
// ================================================================
ResultList.propTypes = {
  loading: PT.bool,
  data: PT.arrayOf(
    PT.shape({ id: PT.number.isRequired, url: PT.string.isRequired })
  ),
  onScrollEnd: PT.func.isRequired,
  onSelect: PT.func,
  selected: PT.instanceOf(Set)
};
