import image from 'public/img/no_results.svg';

// Required libs import
// ================================================================
import React, { useCallback, useEffect } from 'react';
import PT from 'prop-types';

const HIGHLIGHTED_CSS_CLASS = 'state-highlighted';

// Component declaration
// ================================================================
export default function NoResults(props) {
  const {
    queryInputId,
    sortingId,
    providersId,
    queryInputSelector,
    providersSelector,
    sortingSelector,
    submitId,
    submitSelector,
    resetId,
    resetSelector
  } = props;

  // Add specific class to from control when anchor is hovered
  // ================================================================
  const onMouseEnter = useCallback(({ target }) => {
    const $el = document.querySelector(target.dataset.selector || target.hash);
    if ($el) {
      $el.classList.add(HIGHLIGHTED_CSS_CLASS);
    }
  }, []);

  // Remove specific class from control when anchor loses hover
  // ================================================================
  const onMouseLeave = useCallback(({ target }) => {
    const $el = document.querySelector(target.dataset.selector || target.hash);
    if ($el) {
      $el.classList.remove(HIGHLIGHTED_CSS_CLASS);
    }
  }, []);

  // Disable click for an anchors
  // ================================================================
  const onClick = useCallback((e) => e.preventDefault(), []);

  // Remove all highlighted css classes when component is about to
  // be unmounted
  // ================================================================
  useEffect(
    () => () =>
      Array.from(
        document.querySelectorAll(`.${HIGHLIGHTED_CSS_CLASS}`)
      ).forEach((el) => el.classList.remove(HIGHLIGHTED_CSS_CLASS)),
    []
  );

  return (
    <div className='NoResults layout-container'>
      <img
        src={image}
        className='NoResults-illustration'
        alt='No items found illustration'
      />
      <div className='NoResults-content'>
        <h2 className='NoResults-title'>Trendolizer search</h2>
        <h3 className='NoResults-title'>
          Simple search engine to check materials
        </h3>
        <ol className='NoResults-steps'>
          <li>
            <a
              href={queryInputId}
              data-selector={queryInputSelector}
              className='NoResults-anchor'
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              Enter a comma separated list of keywords or phrases to find
              matching content in Trendolizer.
            </a>
          </li>
          <li>
            <a
              href={sortingId}
              data-selector={sortingSelector}
              className='NoResults-anchor'
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              Select how to sort results from the dropdown.
            </a>
          </li>
          <li>
            <a
              href={providersId}
              data-selector={providersSelector}
              className='NoResults-anchor'
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              To limit your search to content from specific services, select
              them from the dropdown menu.
            </a>
          </li>
          <li>
            <a
              href={submitId}
              data-selector={submitSelector}
              className='NoResults-anchor'
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              Smash &#34;Search&#34; and wait for results
            </a>
          </li>
          <li>
            <a
              href={resetId}
              data-selector={resetSelector}
              className='NoResults-anchor'
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              If you wan&#39;t to search something else, you can reset app via
              button
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
}

// PropTypes declaration
// ================================================================
NoResults.propTypes = {
  queryInputId: PT.string,
  queryInputSelector: PT.string,
  sortingId: PT.string,
  sortingSelector: PT.string,
  providersId: PT.string,
  providersSelector: PT.string,
  submitId: PT.string,
  submitSelector: PT.string,
  resetId: PT.string,
  resetSelector: PT.string
};
