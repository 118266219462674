// Required libs import
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Trendolizer UI imports
// ================================================================
import Dialog from 'trendolizer-ui/build/Dialog';
import Button from 'trendolizer-ui/build/Button';
import LinearProgress from 'trendolizer-ui/build/LinearProgress';
import Notification from 'trendolizer-ui/build/Notification';
import TextInput from 'trendolizer-ui/build/TextInput';

const togglePasswordType = ({ passwordType }) => ({
  passwordType: passwordType === 'password' ? 'text' : 'password'
});

// Component declaration
// ================================================================
export default class LoginModal extends React.Component {
  // Proptypes declaration
  // ===============================================================
  static propTypes = {
    id: PT.string.isRequired,
    show: PT.bool,
    title: PT.string,
    maxWidth: PT.string,
    service: PT.shape({
      get: PT.func.isRequired
    }).isRequired,
    onClose: PT.func.isRequired,
    onSubmit: PT.func.isRequired
  };

  static defaultProps = {
    title: 'Login dialog',
    maxWidth: '420px'
  };

  // State declaration
  // ================================================================
  state = {
    loading: false,
    success: false,
    apiError: null,
    values: {
      username: '',
      password: ''
    },
    passwordType: 'password'
  };

  // Set input type of password visibility toggle
  // ===============================================================
  setPasswordType = (e) => {
    e.preventDefault();
    this.setState(togglePasswordType);
  };

  // Reset component state
  // ===============================================================
  resetState = () =>
    this.setState({ loading: false, apiError: null, success: false });

  // Save values of form inputs
  // ===============================================================
  setFormValue = ({ name, value }) =>
    this.setState(({ values }) => ({ values: { ...values, [name]: value } }));

  // Handle login and user fetch logic
  // ===============================================================
  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Reset component state
      // ===============================================================
      this.setState({ loading: true, apiError: null, success: false });

      // Login with credenticals given
      // ===============================================================
      const { success } = await this.props.service.get(
        'login',
        this.state.values
      );

      // If login successfull - fetch user and invoke callback
      // ===============================================================
      if (success) {
        const user = await this.props.service.get('user');
        this.setState({ loading: false, apiError: null, success: true });
        this.props.onSubmit({ user });
      }
    } catch (err) {
      // If error happeneded - catch and display it
      // ===============================================================
      this.setState({
        loading: false,
        success: true,
        apiError: err.toString()
      });
    }
  };

  render() {
    const { id, show, title, maxWidth, onClose } = this.props;

    const { passwordType, loading, success, values, apiError } = this.state;

    return (
      <Dialog
        id={id}
        show={show}
        maxWidth={maxWidth}
        title={title}
        maxHeight='auto'
        onClose={onClose}
        className='LoginModal'
        header={<LinearProgress loading={loading} success={success} />}
        footer={
          <Button
            appearance='accent'
            disabled={loading}
            form='login-form'
            type='submit'
            text='Log in'
          />
        }
        footerCloseButton
      >
        <form
          id='login-form'
          onSubmit={this.handleSubmit}
          onReset={onClose}
          className='LoginModal-form'
        >
          {apiError ? (
            <div className='LoginModal-row'>
              <Notification id={0} type='error' onClick={this.resetState}>
                {apiError}
              </Notification>
            </div>
          ) : null}
          <div className='LoginModal-row'>
            <TextInput
              id='username'
              name='username'
              type='text'
              disabled={loading}
              value={values.username}
              onChange={this.setFormValue}
              label='Username'
              placeholder='Your username or email'
            />
          </div>
          <div className='LoginModal-row PasswordField'>
            <TextInput
              id='password'
              name='password'
              type={passwordType}
              disabled={loading}
              value={values.password}
              onChange={this.setFormValue}
              label='Password'
              placeholder='Type password here'
            />
            <Button
              icon={passwordType === 'password' ? 'show' : 'hide'}
              onClick={this.setPasswordType}
            />
          </div>
        </form>
      </Dialog>
    );
  }
}
