// Images import
// ================================================================
import logo from 'public/img/logo.svg';

// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

// Utility functions and constants
// ================================================================
import omit from 'trendolizer-ui/build/util/omit';
import {
  FIELDS,
  LIMIT,
  HISTORY_KEY,
  HISTORY_LIMIT,
  SEARCH_PARAM
} from './const';
import { decodeHtml, localStorageSet, localStorageGet } from './util';

// Trendolizer UI imports
// ================================================================
import LinearProgress from 'trendolizer-ui/build/LinearProgress';
import Notification from 'trendolizer-ui/build/Notification';
import Button from 'trendolizer-ui/build/Button';

// Service import
// ================================================================
import JsonpService from 'trendolizer-client/src/jsonp';

// Application components
// ================================================================
import QueryForm from './Components/QueryForm';
import UserInfo from './Components/UserInfo';
import ResultList from './Components/ResultList';
import LoginModal from './Components/LoginModal';
import CreateColumn from './Components/CreateColumn';

function classNameMeta(root, ...args) {
  const cn = classNames(root, ...args);
  return [
    cn,
    (sub, ...additional) => classNames(`${root}-${sub}`, ...additional)
  ];
}

// Init context
// ================================================================
const Context = React.createContext('ServiceProvider');

// Init state declaration
// ================================================================
const initState = {
  loading: false,
  apiError: null,
  results: [],
  user: { id: null },
  cachedQuery: {},
  notification: null,
  selected: new Set(),
  showLoginModal: false
};

// Unified error state composer, used in ALL async requests
// ================================================================
const composeErrorState = (err) => {
  const apiError = err.toString();
  return {
    loading: false,
    apiError,
    notification: {
      type: 'error',
      text: apiError
    },
    showLoginModal: apiError.indexOf('logged in') > 0
  };
};

// Component declaration
// ================================================================
export default class Application extends React.Component {
  // Proptypes declaration
  // ================================================================
  static propTypes = {
    baseUrl: PT.string.isRequired,
    className: PT.string.isRequired,
    children: PT.node
  };

  form = React.createRef();

  // State declaration
  // ================================================================
  state = initState;

  // Init service in constructor
  // ================================================================
  constructor(props) {
    super(props);
    this.decoder = decodeHtml();
    this.service = new JsonpService({
      url: props.baseUrl
    });
  }

  componentDidMount() {
    // Load user info on app initialization
    // ================================================================
    (async () => {
      try {
        const user = await this.service.get('user');
        this.setUser({ user });
      } catch (err) {
        this.setState(composeErrorState(err));
      }
    })();
  }

  checkInitQuery = async () => {
    try {
      const query = new URL(window.location.href).searchParams.get(
        SEARCH_PARAM
      );
      if (query && this.form && this.form.current) {
        await this.form.current.setFormValue({ name: 'query', value: query });
        await this.form.current.onSubmit();
      }
    } catch (err) {
      this.setState(composeErrorState(err));
    }
  };

  // Fetch results based on query provided by form component
  // ================================================================
  fetchResults = async (params) => {
    try {
      this.setState({ loading: true, apiError: null });
      const sorting = { sort: params.sort, direction: 'desc' };
      if (params.sort.startsWith('found_')) {
        sorting.sort = 'found';
        sorting.direction = params.sort.split('_').pop();
      }
      let results = await this.service.get('links', {
        ...params,
        ...sorting,
        limit: LIMIT,
        fields: FIELDS.join(',')
      });

      results = results.map((item) => {
        item.title = this.decoder(item.title);
        item.description = this.decoder(item.description);
        return item;
      });

      this.setState(
        {
          results: params.offset
            ? this.state.results.slice(0, params.offset).concat(results)
            : results,
          cachedQuery: params,
          loading: false,
          apiError: null
        },
        () => {
          const history = localStorageGet(HISTORY_KEY) || [];
          const newValueKey = JSON.stringify(omit(params, 'offset'));
          const duplicateIdx = history.findIndex(
            ([key]) => key === newValueKey
          );

          if (duplicateIdx > -1) {
            history.splice(duplicateIdx, 1);
          }

          history.unshift([newValueKey, results.length]);

          if (history.length > HISTORY_LIMIT) {
            history.length = HISTORY_LIMIT;
          }
          localStorageSet(HISTORY_KEY, history);
        }
      );
    } catch (err) {
      this.setState(composeErrorState(err));
    }
  };

  // Run fetch items with cached query and offset (infiniteloading)
  // ================================================================
  fetchMoreItems = (offset) => {
    if (this.state.results.length >= LIMIT) {
      return this.fetchResults({
        ...this.state.cachedQuery,
        offset
      });
    }
    return Promise.resolve();
  };

  // Forcefully hide login modal
  // ================================================================
  closeLoginModal = () => {
    this.setState({ showLoginModal: false });
  };

  // Reset app
  // ================================================================
  resetState = () => this.setState(({ user }) => ({ ...initState, user }));

  // Clear notification
  // ================================================================
  clearNotification = () => this.setState({ notification: null });

  // Set app user after login and hide modal
  // ================================================================
  setUser = ({ user }) =>
    this.setState({ user, showLoginModal: false }, this.checkInitQuery);

  toggleSelection = ({ value }) =>
    this.setState(({ selected }) => {
      if (selected.has(value)) {
        selected.delete(value);
      } else {
        selected.add(value);
      }
      return { selected };
    });

  toggleSelectionAll = (e) => {
    e.preventDefault();
    this.setState(({ selected }) => {
      const { results } = this.state;
      if (selected.size) {
        return { selected: new Set() };
      } else {
        return { selected: new Set(results.map(({ url }) => url)) };
      }
    });
  };

  copySelectionToClip = (e) => {
    e.preventDefault();
    const value = this.state.selected.join('\n');
    const $el = document.createElement('textarea');
    $el.style.cssText = 'opacity:0;position:fixed;bottom:100%;';
    $el.value = value;
    document.body.appendChild($el);
    $el.select();
    document.execCommand('copy');
    document.body.removeChild($el);
    this.setState({
      notification: {
        type: 'success',
        text: 'Links for selected results have been copied to your clipboard."'
      }
    });
  };

  // Logout, reset user, show login modal
  // ================================================================
  logOut = async () => {
    try {
      await this.service.get('logout');
      this.setState({
        user: { id: null, showLoginModal: true }
      });
    } catch (err) {
      this.setState(composeErrorState(err));
    }
  };

  createColumn = async (params) => {
    try {
      const { id, name } = await this.service.post('column', params);
      if (id) {
        await this.service.put('column', {
          id,
          data: this.state.cachedQuery
        });
      }
      this.setState({
        notification: {
          type: 'success',
          text: `Column ${name} created successfully, id: ${id}`
        }
      });
      return true;
    } catch (err) {
      this.setState(composeErrorState(err));
      return false;
    }
  };

  render() {
    const { className, children } = this.props;
    const {
      apiError,
      results,
      showLoginModal,
      loading,
      selected,
      user,
      notification
    } = this.state;
    const [, cn] = classNameMeta(className);
    return (
      <Context.Provider value={this.service}>
        <header className={cn('header', 'debug-typography-rhyth')}>
          <h1 className={cn('header-logo')}>
            <img src={logo} />
            <b>Search</b>
          </h1>
          <QueryForm
            loading={loading}
            ref={this.form}
            onSubmit={this.fetchResults}
            onSelect={this.toggleSelectionAll}
            showDeselect={!!selected.size}
            onReset={this.resetState}
          />
          <UserInfo
            fullname={user.fullname}
            position={user.position}
            image={user.image}
            handleLogout={this.logOut}
          />
          {results.length ? (
            <CreateColumn
              disabled={loading}
              className={cn('action-modal')}
              onSubmit={this.createColumn}
            />
          ) : null}
        </header>
        <LinearProgress
          loading={loading}
          success={!loading && !!results.length}
        />
        <form className={cn('content')}>
          <ResultList
            loading={loading}
            error={apiError}
            data={results}
            onScrollEnd={this.fetchMoreItems}
            onSelect={this.toggleSelection}
            selected={selected}
          />
        </form>
        <LoginModal
          id='LoginForm'
          show={showLoginModal}
          service={this.service}
          onSubmit={this.setUser}
          onClose={this.closeLoginModal}
        />
        {children}
        {notification ? (
          <Notification
            id={0}
            type={notification.type}
            delay={2500}
            className={cn('notification')}
            onClick={this.clearNotification}
          >
            {notification.text}
          </Notification>
        ) : null}
        {selected.size ? (
          <Button
            icon='copy'
            appearance='accent'
            className={cn('copy-action')}
            onClick={this.copySelectionToClip}
            title='Copy selected stories url`s to clipboard'
          />
        ) : null}
      </Context.Provider>
    );
  }
}
