export const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

export function decodeHtml() {
  const txt = document.createElement('textarea');
  return (html) => {
    txt.innerHTML = html;
    return txt.value;
  };
}

export const localStorageGet = (key) =>
  JSON.parse(window.localStorage.getItem(key));

export const localStorageSet = (key, val) =>
  window.localStorage.setItem(key, JSON.stringify(val));

export const addTypographyDebug = () => {
  if (process.env.NODE_ENV === 'development') {
    document.body.addEventListener(
      'dblclick',
      ({ target, ctrlKey, shiftKey }) => {
        if (ctrlKey && shiftKey) {
          if (!target.classList.contains('debug-typography-rhythm')) {
            target.classList.add('debug-typography-rhythm');
          } else {
            target.classList.remove('debug-typography-rhythm');
          }
        }
      }
    );
  }
};
