// Required libs import
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Trendolizer UI imports
// ================================================================
import Image from 'trendolizer-ui/build/Image';
import Icon from 'trendolizer-ui/build/Icon';
import Toggler from 'trendolizer-ui/build/Toggler';
import DateTime from 'trendolizer-ui/build/DateTime';

// Utility functions and constants
// ================================================================
import { STATS } from '../const';
import shortenNumber from 'trendolizer-ui/build/util/shortenNumber';

const statsMap = Object.entries(STATS);

// Component declaration
// ================================================================
export default function Result(props) {
  const { id, title, url, image, found, description, onSelect, selected } =
    props;
  return (
    <article id={id} className='Result layout-container'>
      <header className='Result-header'>
        {onSelect ? (
          <Toggler
            id={`selector-${id}`}
            type='checkbox'
            name='select-url'
            className='Result-selector'
            checked={selected}
            onChange={onSelect}
            value={url}
          />
        ) : null}
        <h1 className='Result-title'>
          <a href={url} rel='noreferrer' target='_blank'>
            {title}
          </a>
        </h1>
      </header>
      <a href={url} rel='noreferrer' target='_blank' className='Result-url'>
        {url}
      </a>
      <Image src={image} className='Result-media' />
      <div className='Result-description'>{description}</div>
      <DateTime
        data={found}
        className='Result-stat'
        format='YYYY-MM-DD HH:mm'
      />
      {statsMap.map(([prop, { icon, title }]) => (
        <div key={prop} className='Result-stat' title={title}>
          <Icon icon={icon} />
          <span
            className='Result-stat-val'
            title={`Total ${prop}: ${props[prop]}.`}
          >
            {shortenNumber(props[prop], 0)}
          </span>{' '}
          <span
            className='Result-stat-rate'
            title={`Increase by: ${props[`rate_${prop}`]} ${prop}/hour.`}
          >
            ({shortenNumber(props[`rate_${prop}`], 0)}/hr)
          </span>{' '}
          <span
            className='Result-stat-max'
            title={`Maximum recorded rate ${
              props[`maxrate_${prop}`] || 0
            } ${prop}/hour.`}
          >
            (max {shortenNumber(props[`maxrate_${prop}`] || 0, 0)}/hr)
          </span>
        </div>
      ))}
    </article>
  );
}

Result.propTypes = {
  id: PT.number.isRequired,
  hash: PT.string.isRequired,
  title: PT.string.isRequired,
  url: PT.string.isRequired,
  found: PT.string.isRequired,
  language: PT.string,
  description: PT.string,
  image: PT.string,
  likes: PT.number,
  rate_likes: PT.number,
  maxrate_likes: PT.number,
  comments: PT.number,
  rate_comments: PT.number,
  maxrate_comments: PT.number,
  views_video: PT.number,
  rate_views_video: PT.number,
  maxrate_views_video: PT.number,
  votes_video: PT.number,
  rate_votes_video: PT.number,
  maxrate_votes_video: PT.number,
  selected: PT.bool,
  onSelect: PT.func
};
