export const ROOT_ID = 'root';

export const BASEURL = 'http://api.trendolizer.com/v4';

export const BASE_V3_URL = process.env.TRENDOLIZER_ENDPOINT;

export const SCROLLBAR_CSS_VAR = 'layout-scrollbar-size';

export const LIMIT = 20;

export const HISTORY_LIMIT = 10;

export const HISTORY_KEY = 'app_history';

export const PROVIDER_KEY = 'LIKE(url)';

export const DATA_PROVIDERS = Object.freeze({
  YouTube: 'youtube.com',
  Vimeo: 'vimeo.com',
  Telegram: 't.me',
  Twitter: 'twitter.com',
  BrandNewTube: 'brandnewtube.com',
  Brighteon: 'brighteon.com',
  DailyMotion: 'dailymotion.com',
  DropSpace: 'drop.space',
  Dumpert: 'dumpert.nl',
  Lbry: 'lbry.com',
  Gettr: 'gettr.com/post',
  LiveLeak: 'liveleak.com',
  Odysee: 'odysee.com',
  PeerTube: 'hitchtube.com',
  Rumble: 'rumble.com',
  Streamable: 'streamable.com',
  TikTok: 'tiktok.com',
  TV2: 'tv2.no',
  Utreon: 'utreon.com',
  WorldStar: 'worldstar.com',
  WorldStarHipHop: 'worldstarhiphop.com'
});

export const SORT_OPTIONS = Object.freeze({
  found_desc: 'Most recent',
  found_asc: 'Oldest',
  likes: 'Likes',
  rate_likes: 'Rate Likes',
  maxrate_likes: 'Maxrate Likes',
  tweets: 'Tweets',
  rate_tweets: 'Rate Tweets',
  maxrate_tweets: 'Maxrate Tweets',
  views_video: 'Views',
  rate_views_video: 'Rate Views',
  maxrate_views_video: 'Maxrate Views',
  votes_video: ' Votes',
  rate_votes_video: 'Rate Votes',
  maxrate_votes_video: 'Maxrate Votes',
  comments: 'Comments',
  rate_comments: 'Rate Comments',
  maxrate_comments: 'Maxrate Comments'
});

export const STATS = {
  likes: { icon: 'thumbup', title: 'Facebook likes' },
  comments: { icon: 'comment', title: 'Facebook comments' },
  views_video: { icon: 'show', title: 'Video views' },
  votes_video: { icon: 'like', title: 'Video upvotes' }
};

export const FIELDS = Object.keys(STATS).reduce(
  (acc, prop) => {
    acc.push(prop);
    acc.push(`rate_${prop}`);
    acc.push(`maxrate_${prop}`);
    return acc;
  },
  ['id', 'hash', 'title', 'language', 'description', 'url', 'image', 'found']
);

export const SEARCH_PARAM = 'q';
