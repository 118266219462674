// Required libs import
// ================================================================
import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Utility functions and constants
// ================================================================
import { PROVIDER_KEY } from '../const';

const foundSortStr = {
  found_desc: 'Most recent',
  found_asc: 'Oldest'
};

// Component declaration
// ================================================================
export default function HistoryBlock(props) {
  const { data, className, onClick } = props;
  return (
    <div className={cn('HistoryBlock', className)}>
      {data.map(([requestString, numResults]) => {
        const request = JSON.parse(requestString);
        request[PROVIDER_KEY] = (request[PROVIDER_KEY] || '')
          .replace(/%/g, '')
          .split(',');
        return (
          <button
            key={requestString}
            className='HistoryBlock-item'
            onClick={(e) => {
              e.preventDefault();
              onClick(request);
            }}
          >
            <span className='HistoryBlock-param'>
              <b>Search: </b>
              {request.search}
              {', '}
            </span>
            <span className='HistoryBlock-param'>
              <b>From: </b>
              {request[PROVIDER_KEY].length}
              {' sources, '}
            </span>
            <span className='HistoryBlock-param'>
              <b>Sort by: </b>
              {foundSortStr[request.sort] || request.sort}
            </span>
            <em className='HistoryBlock-resultnum'>
              Results: <b>{numResults}</b>
            </em>
          </button>
        );
      })}
    </div>
  );
}

// PropTypes declaration
// ================================================================
HistoryBlock.propTypes = {
  className: PT.string,
  data: PT.arrayOf(PT.array),
  onClick: PT.func.isRequired
};
