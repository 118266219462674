// Import application CSS files
// ================================================================
import 'public/index.css';

// Required libraries
// ================================================================
import React from 'react';
import { createRoot } from 'react-dom/client';

// Utility functions and constants
// ================================================================
import { set } from 'trendolizer-ui/build/util/cssVar';
import { ROOT_ID, SCROLLBAR_CSS_VAR, BASE_V3_URL } from './const';
import { getScrollbarWidth } from './util';

// Application components
// ================================================================
import Application from './Application';

// Set current scrollbarsize as css var
// ================================================================
set(SCROLLBAR_CSS_VAR, `${getScrollbarWidth()}px`);

// Get root element for an application, apply classname
// ================================================================
const rootClassName = 'Application';
const rootEl = document.getElementById(ROOT_ID);
rootEl.classList.add(rootClassName);
const $root = createRoot(rootEl);

// Render application
// ================================================================
$root.render(<Application baseUrl={BASE_V3_URL} className={rootClassName} />);
