// Required libs import
// ================================================================
import React, { createRef } from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Trendolizer UI imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';
import TextInput from 'trendolizer-ui/build/TextInput';

// Component declaration
// ================================================================
export default class CreateColumn extends React.Component {
  static propTypes = {
    className: PT.string,
    disabled: PT.bool,
    title: PT.string,
    onDoubleClick: PT.func,
    onSubmit: PT.func.isRequired
  };

  state = {
    name: '',
    open: false,
    done: false
  };

  $root = createRef();

  setOpen = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  setHide = (e) => {
    if (!this.$root.current.contains(e.target)) {
      this.setState({
        open: e.target.id === 'create-column-trigger'
      });
    }
  };

  setInputValue = ({ value }) => this.setState({ name: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    const done = await this.props.onSubmit({ name: this.state.name });
    this.setState({ done }, () => {
      if (done) {
        setTimeout(() => this.setState({ open: false, done: false }), 3000);
      }
    });
  };

  componentDidMount = () => {
    document.body.addEventListener('click', this.setHide);
  };

  componentWillUnmount = () => {
    document.body.removeEventListener('click', this.setHide);
  };

  render() {
    const { open, done, name } = this.state;
    const { className, disabled, title } = this.props;

    let CONTENT = (
      <Button
        appearance='accent'
        block
        id='create-column-trigger'
        className='CreateColumn-trigger'
        loading={disabled}
        icon='plus'
        onClick={this.setOpen}
      />
    );

    if (open && !done) {
      CONTENT = (
        <form
          title={title}
          onSubmit={this.handleSubmit}
          className='CreateColumn-form'
        >
          <p className='CreateColumn-title'>
            Create column to track this search
          </p>
          <TextInput
            id='column-name'
            name='name'
            value={name}
            autoFocus
            type='text'
            required
            disabled={disabled}
            placeholder='Column name'
            className='CreateColumn-input'
            onChange={this.setInputValue}
          />
          <Button
            appearance='accent'
            loading={disabled}
            type='submit'
            text='Ok'
          />
          <p className='CreateColumn-descr'>
            Just enter a name for a new column, and for your dashboard column
            will be created tracking this search request.
          </p>
        </form>
      );
    } else if (open && done) {
      CONTENT = (
        <>
          <p className='CreateColumn-title'>
            You have successfully created column &quot;{name}&quot;
          </p>
          <small className='CreateColumn-text-supplement'>
            Check and refresh your Trendolizer dashboard
          </small>
        </>
      );
    }

    return (
      <div
        className={cn('CreateColumn', className, {
          'state-open': open
        })}
        ref={this.$root}
      >
        {CONTENT}
      </div>
    );
  }
}
